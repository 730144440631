<template>
    <q-page>
        <router-view/>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1001;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

    </q-page>
</template>


<script>

import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields'

export default {
    name: "Fatture",
    data() {
        return {
        }
    },
    computed: {
        ...mapState({
            is_loading: state => state.gestionePratiche.is_loading
        }),
        ...mapFields('gestioneFatture', {
            is_loading: "is_loading"
        }),
    },
    async mounted() {
        this.is_loading = true;
    }
}
</script>

<style scoped lang="scss">


</style>


